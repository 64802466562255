import {
  startRegistration,
  startAuthentication,
} from '@simplewebauthn/browser';

export const generate_email = function () {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 12; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text + '@gmail.com';
};

export const registerUser = async () => {
  const response = await fetch('/generate-registration-options');
  const options = await response.json();
  const attResp = await startRegistration(options);
  console.log(attResp);
  const verificationResp = await fetch('/verify-registration', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      challenge: options.challenge,
      response: attResp,
    }),
  });

  // Wait for the results of verification
  const verificationJSON = await verificationResp.json();
  console.log(verificationJSON);
  if (verificationJSON.verfied) {
  }
  // const registrationResponse = await fetch('/verify-registration-response', {
  //   method: 'POST',
  //   body: JSON.stringify({ email, credential }),
  // });
  // const registrationResponseBody = await registrationResponse.json();
  // console.log(registrationResponseBody);
};

export const loginUser = async () => {
  const response = await fetch('/generate-registration-options');
  const options = await response.json();
  const attResp = await startAuthentication(options);
  console.log(attResp);
  // const verificationResp = await fetch('/verify-login', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     challenge: options.challenge,
  //     response: attResp,
  //   }),
  // });
};
