import { cborDecode, decode, safeByteEncode } from './utils.js';

// const url = "https://api.passkeys.neal.codes";
const url = "/api";

const makeRequest = (endpoint, data) =>
  fetch(
    new Request(`${url}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    }),
  );

function getPublicKey(attestation) {
  if (attestation?.getPublicKey) {
    return attestation.getPublicKey();
  }
  throw new Error(
    'No getPublicKey available. Please change to supported browser',
  );
}
function getPublicKeyAlgorithm(attestation) {
  if (attestation?.getPublicKeyAlgorithm) {
    return attestation.getPublicKeyAlgorithm();
  }
  throw new Error(
    'No getPublicKeyAlgorithm available. Please change to supported browser',
  );
}

export const Attestation = {
  async generate(userId) {
    const response = await makeRequest('attestation/generate', {
      userId,
    });
    return await response.json();
  },

  async store(credential) {
    try {
      const attestation = credential.response;

      // DEBUG:
      console.log(JSON.parse(decode(attestation.clientDataJSON)));
      // DEBUG:
      console.log(cborDecode(new Uint8Array(attestation.attestationObject)));
      // DEBUG:
      // @ts-ignore
      window.attestation = credential;

      const payload = {
        kid: credential.id,
        clientDataJSON: safeByteEncode(attestation.clientDataJSON),
        attestationObject: safeByteEncode(attestation.attestationObject),
        pubkey: safeByteEncode(getPublicKey(attestation)),
        coseAlg: getPublicKeyAlgorithm(attestation),
      };

      const response = await makeRequest('attestation/store', payload);
      return await response.json();
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
}

export const Assertion = {
  async generate() {
    const response = await makeRequest('assertion/generate', {});
    return await response.json();
  },

  async verify(credential) {
    const assertion = credential.response;

    // DEBUG:
    console.log(JSON.parse(decode(assertion.clientDataJSON)));
    // DEBUG:
    console.log(assertion.authenticatorData);
    // DEBUG:
    // @ts-ignore
    window.assertion = credential;

    const payload = {
      kid: credential.id,
      clientDataJSON: safeByteEncode(assertion.clientDataJSON),
      authenticatorData: safeByteEncode(assertion.authenticatorData),
      signature: safeByteEncode(assertion.signature),
    };
    const response = await makeRequest('assertion/verify', payload);
    return await response.json();
  }
}
