import { useState } from 'react';
import logo from './prosper.svg';
import './App.css';
import { Card } from './Card';
import { generate_email } from './webauthn';
import { assertion } from './client/assertion.js';
import { attestation } from './client/attestation.js';

const email = generate_email();
const abortController = new AbortController();

const Login = props => {
  const { handleSignInWithEmail, handleSignInWithPasskey } = props;
  const logout = localStorage.getItem('logout');
  const handleReset = () => {
    localStorage.removeItem('logout');
  };
  return (
    <>
      <div onClick={handleReset}>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className="flex flex-col gap-4">
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          type="text"
          placeholder="Username"
          value={email}
        />
        <button
          onClick={handleSignInWithEmail}
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Sign In With Email
        </button>
        {logout === 'true' && (
          <button
            onClick={handleSignInWithPasskey}
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Sign In With Passkey
          </button>
        )}
      </div>
    </>
  );
};

const EmailCode = props => {
  const { handleEmailCode } = props;
  return (
    <>
      <img src={logo} className="App-logo" alt="logo" />
      <div className="flex flex-col gap-4">
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="email-code"
          type="number"
          placeholder="email-code"
          value={1234}
        />
        <button
          onClick={handleEmailCode}
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit Code
        </button>
      </div>
    </>
  );
};

const CardPage = props => {
  const { handleViewCard, handleLogout, isMFA } = props;
  return (
    <>
      <Card isMFA={isMFA} />
      <div style={{ marginTop: '20px' }}>
        <button
          onClick={isMFA ? handleLogout : handleViewCard}
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {isMFA ? 'Logout' : 'View Card'}
        </button>
      </div>
    </>
  );
};

const MFAPage = props => {
  const { handleSignInWithPasskey } = props;
  return (
    <>
      <button
        onClick={handleSignInWithPasskey}
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign In With Passkey
      </button>
    </>
  );
};

const SetupPassKey = props => {
  const { handleSignUpWithPasskey } = props;
  return (
    <>
      <div style={{ marginBottom: '20px', color: 'black', width: '200px' }}>
        You need to create a passkey to view your card details
      </div>
      <button
        onClick={handleSignUpWithPasskey}
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create Passkey
      </button>
    </>
  );
};

function App() {
  const [page, setPage] = useState('login');
  const handleSignInWithEmail = () => {
    setPage('email-code');
  };
  const handleEmailCode = () => {
    setPage('card');
  };
  const handleSignInWithPasskey = async () => {
    // registerUser();
    await assertion(abortController, email);
    setPage('card-with-mfa');
  };
  const handleSignUpWithPasskey = async () => {
    // registerUser();
    await attestation(abortController, email);
    setPage('card-with-mfa');
  };
  const handleViewCard = () => {
    setPage('setup-passkey');
    // setIsUserLoggedIn(false);
  };
  const handleLogout = () => {
    localStorage.setItem('logout', 'true');
    setPage('login');
  };
  return (
    <div className="App">
      <header className="App-header">
        {page === 'login' && (
          <Login
            handleSignInWithEmail={handleSignInWithEmail}
            handleSignInWithPasskey={handleSignInWithPasskey}
            // handleSignUpWithPasskey={handleSignUpWithPasskey}
          />
        )}
        {page === 'email-code' && (
          <EmailCode handleEmailCode={handleEmailCode} />
        )}
        {page === 'setup-passkey' && (
          <SetupPassKey handleSignUpWithPasskey={handleSignUpWithPasskey} />
        )}
        {page === 'card' && (
          <CardPage
            handleViewCard={handleViewCard}
            isMFA={false}
            handleLogout={handleLogout}
          />
        )}
        {page === 'card-with-mfa' && (
          <CardPage
            handleViewCard={handleViewCard}
            isMFA={true}
            handleLogout={handleLogout}
          />
        )}
        {page === 'mfa' && (
          <MFAPage handleSignInWithPasskey={handleSignInWithPasskey} />
        )}
      </header>
    </div>
  );
}

export default App;
