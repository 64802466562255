import logo from './logo.svg';

export const Card = props => {
  const { isMFA } = props;
  return (
    <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img className="h-12 w-12" src={logo} alt="ChitChat Logo" />
      </div>
      <div>
        <div className="text-xl font-medium text-black">Card Number</div>
        <p className="text-gray-500">
          {isMFA ? '9876 2321 2132 1234' : '**** **** **** 1234'}
        </p>
        <div className="mt-4">
          <div className="text-sm font-medium text-black">Card Holder</div>
          <p className="text-gray-500">John Doe</p>
        </div>
        <div className="mt-4">
          <div className="text-sm font-medium text-black">Expires</div>
          <p className="text-gray-500">08/24</p>
        </div>
      </div>
    </div>
  );
};
